import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { Extension } from '@tiptap/core'
import React from 'react'
import { 
    Box, 
    IconButton, 
    Divider, 
    Paper,
    Tooltip,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    useTheme,
    Fade,
    Slide,
    Collapse,
    MenuItem,
    Typography,
    Link as MuiLink,
    Popover,
    Card,
    CardContent,
    CardActions,
} from '@mui/material'
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    Link as LinkIcon,
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignRight,
    FormatAlignJustify,
    FormatClear,
    Undo,
    Redo,
    Title,
    Edit as EditIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material'
import CustomExtensions from './plugins/CustomExtensions'

interface TiptapEditorProps {
    content: string;
    onChange: (html: string) => void;
    onBlur?: () => void;
}

const MenuBar = ({ editor, onOpenLinkDialog }: { editor: any; onOpenLinkDialog: (linkData?: { href: string; text: string; target: string; rel: string; }) => void }) => {
    const theme = useTheme();
    
    if (!editor) {
        return null;
    }

    const toolbarIconProps = {
        size: 'small' as const,
        sx: { 
            padding: '4px',
            borderRadius: 1,
            '&.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
            },
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            }
        }
    };

    const handleLinkClick = () => {
        // Get existing link data if a link is selected
        if (editor.isActive('link')) {
            const attrs = editor.getAttributes('link');

            // Get the full link text
            const { from } = editor.view.state.selection;
            const $pos = editor.state.doc.resolve(from);
            const textNode = $pos.nodeAfter || $pos.nodeBefore;
            
            if (textNode) {
                // Find all text nodes with the same link mark
                const linkMark = textNode.marks.find(mark => mark.type.name === 'link' && mark.attrs.href === attrs.href);
                if (linkMark) {
                    const parent = $pos.parent;
                    let text = '';
                    parent.descendants((node, pos) => {
                        if (node.isText && node.marks.some(mark => 
                            mark.type.name === 'link' && 
                            mark.attrs.href === attrs.href
                        )) {
                            text += node.text;
                        }
                    });
                    onOpenLinkDialog({
                        href: attrs.href || '',
                        text,
                        target: attrs.target || '_blank',
                        rel: attrs.rel || 'noopener noreferrer'
                    });
                }
            }
        } else {
            // Get selected text for new link
            const { from, to } = editor.state.selection;
            onOpenLinkDialog({
                href: '',
                text: editor.state.doc.textBetween(from, to),
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        }
    };

    return (
        <>
            <Paper variant="outlined" sx={{ 
                mb: 1, 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 0.5, 
                p: 0.5,
                backgroundColor: theme.palette.background.default,
            }}>
                <ButtonGroup size="small">
                    <Tooltip title="Undo">
                        <IconButton {...toolbarIconProps} disabled={!editor.can().undo()} onClick={() => editor.chain().focus().undo().run()}>
                            <Undo fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Redo">
                        <IconButton {...toolbarIconProps} disabled={!editor.can().redo()} onClick={() => editor.chain().focus().redo().run()}>
                            <Redo fontSize="small" />
                        </IconButton>
                    </Tooltip> 
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small">
                    <Tooltip title="Bold">
                        <IconButton {...toolbarIconProps} color={editor.isActive('bold') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleBold().run()}>
                            <FormatBold fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Italic">
                        <IconButton {...toolbarIconProps} color={editor.isActive('italic') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleItalic().run()}>
                            <FormatItalic fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Underline">
                        <IconButton {...toolbarIconProps} color={editor.isActive('underline') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleUnderline().run()}>
                            <FormatUnderlined fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small">
                    <Tooltip title="Bullet List">
                        <IconButton {...toolbarIconProps} color={editor.isActive('bulletList') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleBulletList().run()}>
                            <FormatListBulleted fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Numbered List">
                        <IconButton {...toolbarIconProps} color={editor.isActive('orderedList') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                            <FormatListNumbered fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small">
                    <Tooltip title="Paragraph">
                        <IconButton 
                            {...toolbarIconProps} 
                            color={!editor.isActive('heading') ? 'primary' : 'default'}
                            onClick={() => editor.chain().focus().setParagraph().run()}
                        >
                            <Title sx={{ fontSize: '1.2rem', opacity: 0.5 }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Heading 1">
                        <IconButton 
                            {...toolbarIconProps} 
                            color={editor.isActive('heading', { level: 1 }) ? 'primary' : 'default'}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        >
                            <Title sx={{ fontSize: '1.4rem' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Heading 2">
                        <IconButton 
                            {...toolbarIconProps} 
                            color={editor.isActive('heading', { level: 2 }) ? 'primary' : 'default'}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        >
                            <Title sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Heading 3">
                        <IconButton 
                            {...toolbarIconProps} 
                            color={editor.isActive('heading', { level: 3 }) ? 'primary' : 'default'}
                            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                        >
                            <Title fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <ButtonGroup size="small">
                    <Tooltip title="Quote">
                        <IconButton {...toolbarIconProps} color={editor.isActive('blockquote') ? 'primary' : 'default'} onClick={() => editor.chain().focus().toggleBlockquote().run()}>
                            <FormatQuote fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add/Edit Link">
                        <IconButton {...toolbarIconProps} color={editor.isActive('link') ? 'primary' : 'default'} onClick={handleLinkClick}>
                            <LinkIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </ButtonGroup>

                <Divider orientation="vertical" flexItem />

                <Tooltip title="Clear Formatting">
                    <IconButton {...toolbarIconProps} onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}>
                        <FormatClear fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Paper>
        </>
    )
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({ content, onChange, onBlur }) => {
    const theme = useTheme();
    const [linkInfoOpen, setLinkInfoOpen] = React.useState(false);
    const [selectedLink, setSelectedLink] = React.useState<{ href: string; text: string; } | null>(null);
    const [linkDialogOpen, setLinkDialogOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [linkUrl, setLinkUrl] = React.useState('');
    const [linkText, setLinkText] = React.useState('');
    const [linkTarget, setLinkTarget] = React.useState('_blank');
    const [linkRel, setLinkRel] = React.useState('noopener noreferrer');
    const [tooltipPosition, setTooltipPosition] = React.useState<{ top: number; left: number; } | null>(null);

    React.useEffect(() => {
        const handleScroll = () => {
            if (linkInfoOpen) {
                handleCloseLinkInfo();
            }
        };

        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, [linkInfoOpen]);

    React.useEffect(() => {
        const updatePosition = () => {
            if (anchorEl && linkInfoOpen) {
                const rect = anchorEl.getBoundingClientRect();
                setTooltipPosition({
                    left: rect.left,
                    top: rect.bottom + 4
                });
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition, true);

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition, true);
        };
    }, [anchorEl, linkInfoOpen]);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (linkInfoOpen) {
                const target = event.target as HTMLElement;
                // Check if click is outside tooltip and not on a link
                if (!target.closest('.link-tooltip') && !target.closest('a')) {
                    handleCloseLinkInfo();
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [linkInfoOpen]);

    const handleOpenLinkDialog = (linkData?: { href: string; text: string; target: string; rel: string; }) => {
        setLinkUrl(linkData?.href || '');
        setLinkText(linkData?.text || '');
        setLinkTarget(linkData?.target || '_blank');
        setLinkRel(linkData?.rel || 'noopener noreferrer');
        setLinkDialogOpen(true);
    };

    const handleLinkClick = (props: { href: string; text: string }, element: HTMLElement) => {
        setSelectedLink(props);
        setAnchorEl(element);
        setLinkInfoOpen(true);
    };

    const handleEditLink = () => {
        setLinkInfoOpen(false);
        setAnchorEl(null);
        if (selectedLink) {
            handleOpenLinkDialog({
                href: selectedLink.href,
                text: selectedLink.text,
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        }
    };

    const handleCloseLinkInfo = () => {
        setLinkInfoOpen(false);
        setAnchorEl(null);
        setTooltipPosition(null);
    };

    const handleLinkSubmit = () => {
        if (!editor || !linkUrl) return;

        if (editor.isActive('link')) {
            // First extend the selection to cover the entire link
            editor.chain().focus().extendMarkRange('link').run();
            
            // Then replace the content and update the link
            editor.chain()
                .focus()
                .insertContent(linkText)
                .setLink({ 
                    href: linkUrl,
                    target: linkTarget,
                    rel: linkRel 
                })
                .run();
        } else {
            // For new links
            if (editor.state.selection.empty) {
                // No text selected, insert new text with link
                editor.chain()
                    .focus()
                    .insertContent({
                        type: 'text',
                        text: linkText,
                        marks: [{
                            type: 'link',
                            attrs: {
                                href: linkUrl,
                                target: linkTarget,
                                rel: linkRel
                            }
                        }]
                    })
                    .run();
            } else {
                // Text is selected, replace it with new text and add link
                editor.chain()
                    .focus()
                    .insertContent(linkText)
                    .setLink({ 
                        href: linkUrl,
                        target: linkTarget,
                        rel: linkRel 
                    })
                    .run();
            }
        }
        setLinkUrl('');
        setLinkText('');
        setLinkTarget('_blank');
        setLinkRel('noopener noreferrer');
        setLinkDialogOpen(false);
        setSelectedLink(null);
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleLinkSubmit();
        }
    };

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: {
                    HTMLAttributes: {},
                },
                paragraph: {
                    HTMLAttributes: {},
                },
                bulletList: {
                    HTMLAttributes: {},
                },
                orderedList: {
                    HTMLAttributes: {},
                },
                listItem: {
                    HTMLAttributes: {},
                },
                blockquote: {
                    HTMLAttributes: {},
                },
            }),
            Link.configure({
                openOnClick: false,
                HTMLAttributes: {
                    rel: 'noopener noreferrer',
                    target: '_blank',
                },
                validate: href => /^https?:\/\//.test(href),
            }),
            Image.configure({
                HTMLAttributes: {},
            }),
            Placeholder.configure({
                placeholder: 'Write something...',
            }),
            Underline.configure({
                HTMLAttributes: {},
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'left',
            }),
            CustomExtensions,
        ],
        content,
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
        onBlur: () => {
            onBlur?.();
        },
        editorProps: {
            attributes: {
                class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
                spellcheck: 'true',
            },
            handleDOMEvents: {
                keydown: (view, event) => {
                    // Let all keyboard events pass through normally
                    return false;
                },
            },
            handleClick: (view, pos, event) => {
                const node = view.state.doc.nodeAt(pos);
                if (!node) return false;
                
                const marks = node.marks;
                const linkMark = marks?.find(mark => mark.type.name === 'link');
                
                if (linkMark) {
                    const element = event.target as HTMLElement;
                    handleLinkClick({ 
                        href: linkMark.attrs.href, 
                        text: node.text || ''
                    }, element);
                    return true;
                }
                return false;
            },
            handlePaste: (view, event) => {
                if (event.clipboardData && event.clipboardData.getData('text/html')) {
                    const html = event.clipboardData.getData('text/html');
                    
                    const div = document.createElement('div');
                    div.innerHTML = html;
                    
                    const fragment = div.innerHTML
                        .replace(/<!--StartFragment-->/g, '')
                        .replace(/<!--EndFragment-->/g, '')
                        .trim();
                    
                    div.innerHTML = fragment;
                    
                    // Remove empty paragraphs
                    div.innerHTML = div.innerHTML.replace(/<p>&nbsp;<\/p>/g, '');
                    
                    // Auto-link URLs in text content
                    const urlRegex = /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?)/g;
                    div.querySelectorAll('*').forEach(el => {
                        if (el.childNodes && el.childNodes.length > 0) {
                            el.childNodes.forEach(node => {
                                if (node.nodeType === Node.TEXT_NODE) {
                                    const text = node.textContent || '';
                                    const matches = text.match(urlRegex);
                                    if (matches) {
                                        let newHtml = text;
                                        matches.forEach(url => {
                                            const fullUrl = url.startsWith('http') ? url : 
                                                `http://${url.startsWith('www.') ? '' : 'www.'}${url}`;
                                            newHtml = newHtml.replace(
                                                url,
                                                `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`
                                            );
                                        });
                                        const span = document.createElement('span');
                                        span.innerHTML = newHtml;
                                        // Replace the text node with the span's content
                                        const parent = node.parentNode;
                                        if (parent) {
                                            while (span.firstChild) {
                                                parent.insertBefore(span.firstChild, node);
                                            }
                                            parent.removeChild(node);
                                        }
                                    }
                                }
                            });
                        }
                    });

                    div.querySelectorAll('*').forEach(el => {
                        const isLink = el.tagName.toLowerCase() === 'a';
                        const href = isLink ? el.getAttribute('href') : null;
                        const attributes = el.attributes;
                        for (let i = attributes.length - 1; i >= 0; i--) {
                            const attr = attributes[i];
                            if (isLink && (attr.name === 'href' || attr.name === 'target' || attr.name === 'rel')) {
                                continue;
                            }
                            el.removeAttribute(attr.name);
                        }
                        
                        if (isLink && href) {
                            el.setAttribute('href', href);
                            el.setAttribute('target', '_blank');
                            el.setAttribute('rel', 'noopener noreferrer');
                        }
                        
                        if (!el.textContent?.trim() && 
                            !['img', 'br', 'hr'].includes(el.tagName.toLowerCase())) {
                            el.remove();
                        }
                        
                        if (el.tagName.toLowerCase() === 'div') {
                            const p = document.createElement('p');
                            p.innerHTML = el.innerHTML;
                            el.replaceWith(p);
                        }
                    });
                    
                    const cleanHtml = div.innerHTML;
                    
                    // Insert the HTML content directly
                    if (editor) {
                        editor.commands.insertContent(cleanHtml);
                    }
                    return true;
                }
                return false;
            }
        }
    })

    return (
        <Paper variant="outlined" sx={{ 
            p: 1,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            borderRadius: '0',
            borderLeftWidth:0,
            borderRightWidth:0,
        }}>
            <Box sx={{
                position: 'sticky',
                top: 64, // Height of the app bar
                zIndex: theme.zIndex.appBar - 1,
                mx: -1,
                mt: -1,
            }}>
                <MenuBar editor={editor} onOpenLinkDialog={handleOpenLinkDialog} />
            </Box>
            <Box sx={{
                mt: 1,
                '.ProseMirror': {
                    minHeight: '100px',
                    outline: 'none',
                    p: 1,
                    '&:focus': {
                        outline: 'none',
                    },
                    '& p': {
                        lineHeight: 1.5,
                    },
                    '& ul, & ol': {
                        padding: '0 1rem',
                    },
                    '& h1, & h2, & h3, & h4, & h5, & h6': {
                        lineHeight: 1.1,
                        margin: '1em 0 0.5em',
                    },
                    '& blockquote': {
                        borderLeft: `3px solid ${theme.palette.divider}`,
                        paddingLeft: theme.spacing(2),
                        color: theme.palette.text.secondary,
                        margin: theme.spacing(1, 0),
                    },
                    '& a': {
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
            }}>
                <EditorContent editor={editor} />
            </Box>

            {/* Link Info Tooltip */}
            {linkInfoOpen && tooltipPosition && (
                <Paper
                    className="link-tooltip"
                    variant="outlined"
                    sx={{
                        position: 'fixed',
                        left: tooltipPosition.left,
                        top: tooltipPosition.top,
                        minWidth: 300,
                        zIndex: theme.zIndex.tooltip,
                        boxShadow: theme.shadows[2],
                    }}
                >
                    <Box sx={{ p: 2, pb: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Text
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            {selectedLink?.text}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            URL
                        </Typography>
                        <MuiLink 
                            href={selectedLink?.href} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick={handleCloseLinkInfo}
                            sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: 0.5,
                                wordBreak: 'break-all'
                            }}
                        >
                            {selectedLink?.href}
                            <OpenInNewIcon sx={{ fontSize: '1rem' }} />
                        </MuiLink>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        p: 1, 
                        pt: 0 
                    }}>
                        <Button 
                            onClick={handleEditLink}
                            size="small"
                            startIcon={<EditIcon />}
                        >
                            Edit
                        </Button>
                    </Box>
                </Paper>
            )}

            {/* Link Edit Dialog */}
            <Dialog open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)}>
                <DialogTitle>{editor?.isActive('link') ? 'Edit Link' : 'Insert Link'}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            autoFocus
                            label="Link Text"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={linkText}
                            onChange={(e) => setLinkText(e.target.value)}
                            onKeyPress={handleKeyPress}
                            disabled={!editor?.state.selection.empty && !editor?.isActive('link')}
                            placeholder="Link text"
                        />
                        <TextField
                            label="URL"
                            type="url"
                            fullWidth
                            variant="outlined"
                            value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="https://example.com"
                        />
                        <TextField
                            select
                            label="Target"
                            fullWidth
                            variant="outlined"
                            value={linkTarget}
                            onChange={(e) => setLinkTarget(e.target.value)}
                            onKeyPress={handleKeyPress}
                        >
                            <MenuItem value="_blank">New Window (_blank)</MenuItem>
                            <MenuItem value="_self">Same Window (_self)</MenuItem>
                        </TextField>
                        <TextField
                            select
                            label="Rel"
                            fullWidth
                            variant="outlined"
                            value={linkRel}
                            onChange={(e) => setLinkRel(e.target.value)}
                            onKeyPress={handleKeyPress}
                        >
                            <MenuItem value="noopener noreferrer">noopener noreferrer</MenuItem>
                            <MenuItem value="noopener">noopener</MenuItem>
                            <MenuItem value="">None</MenuItem>
                        </TextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {editor?.isActive('link') && (
                        <Button 
                            onClick={() => {
                                editor.chain().focus().unsetLink().run();
                                setLinkDialogOpen(false);
                                setSelectedLink(null);
                            }} 
                            color="error"
                        >
                            Remove Link
                        </Button>
                    )}
                    <Button onClick={() => setLinkDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleLinkSubmit} variant="contained" disabled={!linkUrl}>
                        {editor?.isActive('link') ? 'Update' : 'Add'} Link
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default TiptapEditor