import { Extension } from '@tiptap/core';
import { EditorView } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

const processAutoLinks = (html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;

    // Auto-link URLs in text content
    const urlRegex = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:[^\s]*[^\s;])?/g;
    div.querySelectorAll('*').forEach(el => {
        if (el.childNodes && el.childNodes.length > 0) {
            el.childNodes.forEach(node => {
                if (node.nodeType === Node.TEXT_NODE) {
                    const text = node.textContent || '';
                    const matches = text.match(urlRegex);
                    if (matches) {
                        let newHtml = text;
                        matches.forEach(url => {
                            // Only add http:// and www. if the URL doesn't start with http:// or https://
                            const fullUrl = url.startsWith('http') ? url : 
                                (url.startsWith('www.') ? `http://${url}` : `http://www.${url}`);
                            
                            newHtml = newHtml.replace(
                                url,
                                `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`
                            );
                        });
                        const span = document.createElement('span');
                        span.innerHTML = newHtml;
                        // Replace the text node with the span's content
                        const parent = node.parentNode;
                        if (parent) {
                            while (span.firstChild) {
                                parent.insertBefore(span.firstChild, node);
                            }
                            parent.removeChild(node);
                        }
                    }
                }
            });
        }
    });

    return div.innerHTML;
};

const cleanupHtml = (html: string): string => {
    const div = document.createElement('div');
    div.innerHTML = html;

    div.querySelectorAll('*').forEach(el => {
        const isLink = el.tagName.toLowerCase() === 'a';
        const href = isLink ? el.getAttribute('href') : null;
        const attributes = el.attributes;
        for (let i = attributes.length - 1; i >= 0; i--) {
            const attr = attributes[i];
            if (isLink && (attr.name === 'href' || attr.name === 'target' || attr.name === 'rel')) {
                continue;
            }
            el.removeAttribute(attr.name);
        }
        
        if (isLink && href) {
            el.setAttribute('href', href);
            el.setAttribute('target', '_blank');
            el.setAttribute('rel', 'noopener noreferrer');
        }
        
        if (!el.textContent?.trim() && 
            !['img', 'br', 'hr'].includes(el.tagName.toLowerCase())) {
            el.remove();
        }
        
        if (el.tagName.toLowerCase() === 'div') {
            const p = document.createElement('p');
            p.innerHTML = el.innerHTML;
            el.replaceWith(p);
        }
    });

    return div.innerHTML;
};

const processContent = (html: string): string => {
    // Remove empty paragraphs
    let processedHtml = html.replace(/<p>&nbsp;<\/p>/g, '');
    // Auto-link URLs
    processedHtml = processAutoLinks(processedHtml);
    // Clean up HTML
    processedHtml = cleanupHtml(processedHtml);
    return processedHtml;
};

const CustomExtensions = Extension.create({
    name: 'customExtensions',

    addGlobalAttributes() {
        return [
            {
                types: ['textContent'],
                attributes: {
                    lastAutolink: {
                        default: 0,
                        parseHTML: () => null,
                        renderHTML: () => null,
                    },
                },
            },
        ]
    },

    onUpdate() {
        const transaction = this.editor.state.tr;
        const lastChar = transaction.doc.textContent.slice(-1);
        
        // Only process if we're not typing a space
        if (lastChar !== ' ') {
            const html = this.editor.getHTML();
            const processedHtml = processAutoLinks(html);
            if (html !== processedHtml) {
                // Store current cursor position
                const { from, to } = this.editor.state.selection;
                
                this.editor.commands.setContent(processedHtml);
                
                // Restore cursor position at the end
                this.editor.commands.setTextSelection(to);
            }
        }
    },

    onCreate() {
        // Process content only on initial load
        const html = this.editor.getHTML();
        const processedHtml = processContent(html);
        if (html !== processedHtml) {
            this.editor.commands.setContent(processedHtml);
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handlePaste: (view: EditorView, event: ClipboardEvent): boolean => {
                        if (event.clipboardData && event.clipboardData.getData('text/html')) {
                            const html = event.clipboardData.getData('text/html')
                                .replace(/<!--StartFragment-->/g, '')
                                .replace(/<!--EndFragment-->/g, '')
                                .trim();
                            
                            const processedHtml = processContent(html);
                            
                            // Insert the HTML content directly using the editor instance from the extension
                            this.editor.commands.insertContent(processedHtml);
                            return true;
                        }
                        return false;
                    }
                }
            })
        ]
    }
});

export default CustomExtensions; 